import { PageProps } from 'gatsby';
import React, { useContext } from 'react';
import { SelectLocations as SelectLocationsComponent } from '@codeparva/gatsby-theme-layout-components';
import { SEO } from '@codeparva/gatsby-theme-tfw-1/src/components/seo/Seo';
import { FacilityContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/facilityContext';
import { LayoutContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/layoutContext';
import { LocationsContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/locationsContext';
import { SEOGlobalContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/seoContext';
import '@codeparva/gatsby-theme-tfw-1/src/style/app.scss';
import { initGlobalSeo, initPage } from '@codeparva/gatsby-theme-tfw-1/src/utils/initializePage';
import '../override.scss'
type TPageContext = {
  facilityId: string;
  globalSeoDetails: any;
  facilitySmsId: any;
  locationStrapiId: any;
  locationUniqueId: any;
  facilityDetails: any;
  allAvailableLocations: any[];
  viewRatesPage: any[];
  layoutConfigs: {
    navbarConfig: any;
    topbarConfig: any;
    footerConfig: any;
  };
};

type TViewRatesPageProps = PageProps<any, TPageContext>;

interface IViewRatesPageProps extends TViewRatesPageProps {
  setGlobalSeo: React.Dispatch<
    React.SetStateAction<{
      googleAnalyticsId: string;
      googleTagManagerId: string;
      metaTags: never[];
      openGraphMetaTags: {};
      openGraphTwitter: {};
      canonicalUrl: string;
      jsonLdSchema: {};
      pageTitle: string;
    }>
  >;
  setPageSeo: React.Dispatch<
    React.SetStateAction<{
      metaTags: never[];
      openGraphMetaTags: {};
      openGraphTwitter: {};
      canonicalUrl: string;
      jsonLdSchema: {};
      pageTitle: string;
    }>
  >;
}
const SelectLocations = (props: IViewRatesPageProps) => {
  const facilityDetails = React.useContext(FacilityContext);
  const locationDetails = React.useContext(LocationsContext);
  const layoutDetails = React.useContext(LayoutContext);
  const globalSeo = useContext(SEOGlobalContext);
  const {
    pageContext: { allAvailableLocations },
    location,
  }: any = props;
  const { state } = location;
  React.useEffect(() => {
    initGlobalSeo(props, globalSeo);
    initPage(props, facilityDetails, locationDetails, layoutDetails);
  }, [facilityDetails, locationDetails, layoutDetails]);
  return (
    <>
      <SEO />
      {state && (
        <SelectLocationsComponent
          allAvailableLocations={allAvailableLocations}
          dataFrom={state.from}
          componentClicked={state.dataFrom}
        />
      )}
    </>
  );
};

export default SelectLocations;
